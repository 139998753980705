export default function useResponsive() {
  const isMobile = ref(false);
  const isTablet = ref(false);
  const isHandheld = ref(false);
  const isDesktop = ref(false);
  const isLaptop = ref(false);

  onMounted(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
  });

  const updateWidth = () => {
    isMobile.value = window.innerWidth <= 640;
    isTablet.value = window.innerWidth > 640 && window.innerWidth <= 1024;
    isHandheld.value = window.innerWidth <= 1024;
    isDesktop.value = window.innerWidth > 1024;
    isLaptop.value = window.innerWidth < 1280;
  };

  return {
    isMobile,
    isTablet,
    isHandheld,
    isDesktop,
    isLaptop,
  };
}
