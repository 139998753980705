<script setup lang="ts">
import type { TypeSaveableContent } from "~/types/TypeSaveableContent";

const props = defineProps<{
  entry: TypeSaveableContent;
  type: "save" | "follow";
  colour: "navy" | "white";
  portalAction?: boolean;
}>();

const icon = computed(() =>
  props.type === "save" ? ["fairr:save", "fairr:saved"] : ["fairr:follow", "fairr:following"],
);

const emit = defineEmits(["update"]);
</script>

<template>
  <UiSaveContent
    :entry="entry"
    :portal-action="portalAction"
  >
    <template #default="{ saved, status, update }">
      <NuxtIcon
        :name="saved ? (icon[1] ?? '') : (icon[0] ?? '')"
        size="20"
        class="cursor-pointer"
        :class="{
          'animate-opacity-pulse': status === 'pending',
          [`text-${colour}`]: colour,
        }"
        @click="update(); emit('update');"
      />
    </template>

    <template #logged-out>
      <NuxtLink
        to="/login"
        class="group relative block w-fit print:hidden"
      >
        <NuxtIcon
          :name="icon[0] ?? ''"
          size="20"
          class="cursor-pointer"
          :class="{
            [`text-${colour}`]: colour,
          }"
        />
        <UiTooltip text="Log in to be able to follow" />
      </NuxtLink>
    </template>
  </UiSaveContent>
</template>
