export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp();

  // We must be running in the browser, and the page must be hydrating (page load)
  if (!import.meta.client || !nuxtApp.isHydrating) {
    return;
  }

  const { data: userData } = await useFetch("/api/auth/user");

  useUser().value = userData.value
    // Convert dates back into date objects from strings used in API JSON
    ? {
        ...userData.value,
        salesforce: {
          ...userData.value.salesforce,
          contact: {
            ...userData.value.salesforce.contact,
            account: {
              ...userData.value.salesforce.contact.account,
              aumDate: new Date(userData.value.salesforce.contact.account.aumDate ?? ""),
              membershipDate: new Date(userData.value.salesforce.contact.account.membershipDate ?? ""),
            },
          },
        },
      }
    : null;

  const { data: accountData } = await useFetch("/api/auth/account");

  useAccount().value = accountData.value
    ? {
        ...accountData.value,
        membershipDate: new Date(accountData.value.membershipDate),
        relationships: accountData.value.relationships.map((relationship) => ({
          ...relationship,
          date: new Date(relationship.date),
        })),
      }
    : null;
});
