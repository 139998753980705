<script setup lang="ts">
// This component actually outputs Pages and Articles, and should be renamed
import type { Entry } from "contentful";
import {
  type TypeCardBlockProject,
  isTypePage,
  isTypeArticle,
  isTypeExternalPage,
  type TypeProjectSkeleton,
} from "~/types/contentful";

defineProps<{
  fields: TypeCardBlockProject<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();

const { data: allProjects } = await useLazyFetch<
  Entry<TypeProjectSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]
>("/api/projects");

const projectsBySlug = computed(() => Object.fromEntries(allProjects.value?.map((project) => [project.fields.slug, project]) ?? []));
</script>

<template>
  <UiContainer class="py-4 md:py-6">
    <div class="flex flex-wrap gap-4">
      <div
        v-if="fields.displaySectionTitle"
        :id="fields?.title?.replaceAll(' ', '-').toLowerCase()"
        class="w-full"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
      <div class="w-full">
        <div
          v-for="pageOrArticle in fields.project?.filter(
            (item): item is NonNullable<typeof item> => !!item,
          )"
          :key="pageOrArticle.sys.id"
          class="grid grid-cols-12 gap-4 border-t-2 border-ui-grey2 py-4 md:py-5"
        >
          <div class="col-span-12 md:col-span-8 lg:col-span-7">
            <NuxtLink :to="getUrl(pageOrArticle)">
              <h3 class="mb-3 md:mb-4">
                {{ pageOrArticle.fields.title }}
              </h3>
            </NuxtLink>
            <p
              v-if="isTypePage(pageOrArticle)"
              class="max-md:mb-0"
            >
              {{ pageOrArticle.fields.excerpt }}
            </p>
            <p
              v-if="isTypeExternalPage(pageOrArticle)"
              class="max-md:mb-0"
            >
              {{ pageOrArticle.fields.subcopy }}
            </p>

            <UiSaveButtonPrimary
              v-if="pageOrArticle && isTypePage<'WITHOUT_UNRESOLVABLE_LINKS', string>(pageOrArticle) && pageOrArticle.fields.slug && projectsBySlug[pageOrArticle.fields.slug]?.fields.activeProject && projectsBySlug[pageOrArticle.fields.slug]?.fields.type !== 'Statement'"
              :entry="(projectsBySlug[pageOrArticle.fields.slug] as NonNullable<typeof projectsBySlug[0]>)"
              type="follow"
              class="mt-4"
            />
          </div>
          <div
            class="col-span-6 md:col-span-3 md:col-end-13 lg:col-span-2 lg:col-end-13"
          >
            <NuxtImg
              v-if="
                isTypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>(
                  pageOrArticle,
                ) && pageOrArticle.fields.thumbnail?.fields.file
              "
              :src="pageOrArticle.fields.thumbnail.fields.file.url"
              :alt="pageOrArticle.fields.thumbnail.fields.title"
              :width="300"
              :height="300"
              loading="lazy"
            />
            <NuxtImg
              v-else-if="
                (isTypeArticle<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>(
                  pageOrArticle,
                )
                  || isTypeExternalPage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>(
                    pageOrArticle,
                  ))
                  && pageOrArticle.fields.image?.fields.file
              "
              :src="pageOrArticle.fields.image.fields.file.url"
              :alt="pageOrArticle.fields.image.fields.title"
              :width="300"
              :height="300"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </UiContainer>
</template>
