<script setup lang="ts">
import type { Entry } from "contentful";
import {
  type TypeProjectSkeleton,
  type TypeThemeSkeleton,
  type TypeTopicSkeleton,
  isTypeProject,
  isTypeTheme,
  isTypeTopic,
} from "~/types/contentful";

const props = defineProps<{
  copy?: string;
  titles: string[];
  blocks: (
    | string
    | Entry<
        TypeProjectSkeleton | TypeThemeSkeleton | TypeTopicSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      "en-GB"
    >
  )[][];
  scrollOnOpen?: boolean;
  disabled?: boolean;
  asRadio?: number[];
  label?: string;
  asDropdown?: boolean;
  dropdownIndex?: number;
}>();

const emit = defineEmits(["onFilterChange", "onClearClick"]);

const checkedFilters = ref<string[]>([]);
const showPanel = ref(false);
const filterContent = ref<HTMLElement>();

const handleClick = () => {
  showPanel.value = !showPanel.value;

  if (!props.scrollOnOpen) return;

  nextTick(() => filterContent.value?.scrollIntoView());
};

const closeFilterPanel = () => {
  if (showPanel.value) showPanel.value = false;
};

const handleOnFilterChange = (
  checked: boolean,
  id: string,
  blockIndex: number,
) => {
  if (props.asRadio?.includes(blockIndex)) {
    if (checkedFilters.value[blockIndex]) {
      emit("onFilterChange", false, checkedFilters.value[blockIndex], blockIndex);
    }
    // checkedFilters.value = checked ? [id] : [];
  } else if (checked) {
    checkedFilters.value.push(id);
  } else if (!checked) {
    const index = checkedFilters.value.indexOf(id);
    checkedFilters.value.splice(index, 1);
  }

  emit("onFilterChange", checked, id, props.dropdownIndex ?? blockIndex);
};

const handleOnClearClick = () => {
  checkedFilters.value = [];
  radioValues.value.forEach((_, index) => radioValues.value[index] = null);
  emit("onClearClick");
};

const buttonText = computed(() =>
  props.label ? props.label : showPanel.value ? "Close Filters" : "Filter by:",
);

const radioValues = ref<(number | null)[]>([]);
</script>

<template>
  <div
    v-click-outside="closeFilterPanel"
    class="relative flex justify-end"
    :class="{ 'pointer-events-none opacity-50': disabled }"
  >
    <div
      v-if="!asDropdown"
      v-show="showPanel"
      class="absolute right-0 top-[-20px] z-[19] h-[110px] w-full bg-gradient-to-b from-level-standard from-90% to-transparent md:w-[460px]"
    />
    <div
      class="flex h-6 w-full cursor-pointer select-none items-center justify-between rounded-sm border bg-white px-2 text-xs font-medium uppercase leading-none tracking-widest md:min-w-[160px]"
      :class="{ 'z-[102] border-light-royal-blue': showPanel, 'z-[8] border-ui-grey2': !showPanel }"
      @click="handleClick"
    >
      <div>
        {{ buttonText }}
        <span
          v-if="checkedFilters.length > 0 && !showPanel"
          class="text-light-royal-blue"
        >({{ checkedFilters.length }})</span>
      </div>
      <div>
        <NuxtIcon
          v-if="!showPanel"
          name="fairr:filter"
          size="24"
        />
        <NuxtIcon
          v-if="showPanel && checkedFilters.length === 0"
          name="fairr:cross"
          size="24"
        />
        <NuxtIcon
          v-if="showPanel && checkedFilters.length > 0"
          name="fairr:tick"
          size="24"
        />
      </div>
    </div>
    <div
      v-show="showPanel"
      ref="filterContent"
      class="absolute top-[60px] z-[101] max-h-[350px] w-full select-none overflow-y-scroll bg-white p-3 shadow-[0_0_3px_0] shadow-black/10 md:left-[initial]"
      :class="{ '!top-[-20px] left-[-20px] h-lvh !max-h-screen w-[100%+36px] bg-level-standard px-[20px] pb-[40px] pt-7 shadow-[-10px_0_40px_0] md:-right-4 md:w-[510px] md:px-[32px] md:pb-[20px] md:pt-[104px] lg:-right-5 lg:px-[60px] ': !asDropdown }"
      :data-label="label"
    >
      <div
        v-if="copy"
        class="mb-2"
      >
        <p>
          {{ copy }}
        </p>
        <div
          class="pointer-events-none text-end font-bold text-light-royal-blue opacity-0"
          :class="{ 'pointer-events-auto cursor-pointer opacity-100': checkedFilters.length > 0 && !asDropdown }"
          @click="handleOnClearClick"
        >
          Clear Selection ({{ checkedFilters.length }})
        </div>
      </div>
      <div>
        <div
          v-for="(block, index) in blocks"
          :key="JSON.stringify(block)"
          class="group"
        >
          <div v-if="block && block.length > 1">
            <div
              v-if="!asDropdown"
              class="mb-2 mt-4 text-xs font-bold"
            >
              {{ titles[index]?.replace("Filter_", "") }}
            </div>

            <UiRadio
              v-if="asRadio?.includes(index)"
              v-model="radioValues[index]"
              :items="
                block.filter(
                  (block): block is string => typeof block === 'string',
                )
              "
              :unselectable="true"
              :data-title="titles[index]?.replace('Filter_', '')"
              @select="(checked, id) => handleOnFilterChange(checked, id, dropdownIndex || index)"
            />

            <div
              v-for="(item, index2) in block"
              v-else
              :key="index2"
            >
              <div v-if="item && typeof item !== 'string'">
                <UiCheckbox
                  :id="item.sys.id"
                  :value="item.sys.id"
                  :checked="checkedFilters.includes(item.sys.id)"
                  :block-index="index"
                  @on-checkbox-change="handleOnFilterChange"
                >
                  {{
                    isTypeProject(item)
                      ? item.fields?.name
                      : isTypeTheme(item) || isTypeTopic(item)
                        ? item.fields?.title
                        : ""
                  }}
                </UiCheckbox>
              </div>
              <div v-else-if="item && typeof item === 'string'">
                <UiCheckbox
                  :id="item"
                  :value="item"
                  :checked="checkedFilters.includes(item)"
                  :block-index="index"
                  @on-checkbox-change="handleOnFilterChange"
                >
                  {{
                    item.startsWith("year")
                      ? item.replace("year", "").replace(/([A-Z])/g, " $1")
                      : item
                  }}
                </UiCheckbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
