import type { EntrySys } from "contentful";
import { isTypeProject } from "~/types/contentful";
import type { TypeLinkableContent } from "~/types/TypeLinkableContent";

export default (entry: TypeLinkableContent | EntrySys["id"]) => {
  const routes = useRoutes();

  const sysId = typeof entry === "string" ? entry : isTypeProject<"WITHOUT_UNRESOLVABLE_LINKS", string>(entry) ? entry.fields.link?.sys.id ?? "" : entry.sys.id;

  const url = routes.value.find((route) =>
    route.sysId.includes(sysId),
  )?.path;

  if (!url) {
    throw new Error(`Route for ${sysId} not found`);
  }

  return url;
};
