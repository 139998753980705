<script setup lang="ts">
import type { TypeNavigationSubpage, TypePage } from "~/types/contentful";
import { pushDataLayerEvent } from "~/lib/client-data-layer";

const props = defineProps<{
  companyUrl?: string;
  fields: Omit<
    TypeNavigationSubpage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"],
    "items"
  > & {
    items: {
      fields: Pick<
        TypePage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"],
        "title" | "slug" | "parent"
      >;
    }[];
    activeItemIndex?: number;
  };
}>();

const nav = ref<HTMLElement>();
const arrowLeft = ref<HTMLElement>();
const arrowRight = ref<HTMLElement>();

const menuVisible = ref(false);

const activeItemTitle = computed(() => {
  return props.fields.items[props.fields.activeItemIndex ?? 0]?.fields.title;
});

const activeItemSlug = computed(() => {
  return props.fields.items[props.fields.activeItemIndex ?? 0]?.fields.slug?.split("/").pop();
});

const isFullProfile = computed(() => (activeItemTitle.value === "Downloads" || activeItemTitle.value === "Company Info"));

const isLocked = (page: TypePage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">) =>
  page.fields.access?.includes("Member") && !useUser().value;

const downloadEvent = () => {
  if (isFullProfile.value) {
    pushDataLayerEvent("evDownloadProfile", { title: "Download Full Profile", company: props.fields.badge });
  } else {
    pushDataLayerEvent("evDownloadAssessment", { title: "Assessment", company: props.fields.badge, project: activeItemTitle.value });
  }
};

const btnVisibility = () => {
  if (nav.value && arrowLeft.value && arrowRight.value) {
    const scrollLeftValue = Math.ceil(nav.value.scrollLeft);
    const scrollWidth = nav.value.scrollWidth - nav.value.clientWidth;

    arrowLeft.value.style.display = scrollLeftValue > 0 ? "flex" : "none";
    arrowRight.value.style.display = scrollWidth > scrollLeftValue + 2 ? "flex" : "none";
  }
};

const scrollLeft = () => {
  if (nav.value) {
    nav.value.scrollLeft -= 200;
    setTimeout(() => {
      btnVisibility();
    }, 50);
  }
};

const scrollRight = () => {
  if (nav.value) {
    nav.value.scrollLeft += 200;
    setTimeout(() => {
      btnVisibility();
    }, 50);
  }
};

onMounted(() => {
  btnVisibility();
  window.addEventListener("resize", btnVisibility);
});

onUnmounted(() => {
  window.removeEventListener("resize", btnVisibility);
});
</script>

<template>
  <UiContainer
    class="sub-navigation sticky top-0 z-20 border-b border-b-ui-grey2 bg-white text-sm print:hidden"
    container-class="max-md:px-0"
    :class="{
      'z-20': !fields.depth,
      'sticky z-[8] overflow-x-scroll border-b border-b-ui-grey2 bg-ui-grey4 md:top-[52px] md:overflow-auto': fields.depth,
    }"
    :style="{ top: `${48 * (fields.depth || 0)}px` }"
  >
    <button
      class="flex w-full items-start justify-between border-0 border-b-4 border-b-light-royal-blue bg-white px-3 py-[12px] text-sm font-semibold text-light-royal-blue md:hidden"
      @click="() => (menuVisible = !menuVisible)"
    >
      {{ activeItemTitle ? activeItemTitle : "Menu" }}
      <NuxtIcon
        size="20"
        name="fairr:arrow"
      />
    </button>
    <div
      class="flex justify-between gap-4"
      :class="{
        'max-md:hidden': !menuVisible && (fields.badge || !fields.depth),
      }"
    >
      <div
        class="relative w-full"
        :class="useUser().value || fields.tag?.length ? ' lg:max-w-[calc(100%-300px)]' : ' lg:max-w-full' "
      >
        <div
          ref="arrowLeft"
          class="absolute left-0 hidden h-full w-6 cursor-pointer items-center justify-start bg-gradient-to-l from-transparent via-white to-white"
          @click="scrollLeft"
        >
          <NuxtIcon
            name="fairr:arrow"
            size="24"
            class="shrink-0 rotate-90"
          />
        </div>
        <div
          ref="arrowRight"
          class="absolute right-0 hidden h-full w-6 cursor-pointer items-center justify-end bg-gradient-to-r from-transparent via-white to-white"
          @click="scrollRight"
        >
          <NuxtIcon
            name="fairr:arrow"
            size="24"
            class="shrink-0 -rotate-90"
          />
        </div>
        <nav
          ref="nav"
          class="flex items-center overflow-x-hidden"
          @scroll="btnVisibility"
        >
          <div
            class="flex items-center md:gap-4"
            :class="{ 'max-md:w-full max-md:flex-col max-md:items-start': !fields.depth, 'max-md:gap-2': fields.depth }"
          >
            <NuxtLink
              v-for="(item, index) in fields.items"
              :id="'tab'+item.fields.title.replaceAll(' ', '')"
              :key="item.fields.slug"
              class="last-child:max-md:pr-3 select-none whitespace-nowrap max-md:px-3"
              :class="{
                'text-light-royal-blue':
                  index === fields.activeItemIndex
                  || (item.fields.parent
                    && getUrl(
                      item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                    ) === useRoute().path),
                'hover:text-light-royal-blue': !isLocked(
                  item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                ),
                'text-ui-grey3': isLocked(
                  item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                ),
              }"
              :to="
                item.fields.parent
                  ? getUrl(
                    item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                  )
                  : item.fields.slug
              "
              @click="() => (menuVisible = false)"
            >
              <span
                class="flex items-center gap-2 border-b-4 border-b-white pb-[calc(1rem-4px)] pt-3"
                :class="{
                  '!border-b-light-royal-blue': index === fields.activeItemIndex
                    || (item.fields.parent
                      && getUrl(
                        item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                      ) === useRoute().path),
                  '[&:hover]:border-b-light-royal-blue': !isLocked(
                    item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                  ),
                }"
              >
                {{ item.fields.title }}
                <NuxtIcon
                  v-if="
                    isLocked(
                      item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                    )
                  "
                  size="16"
                  name="fairr:lock"
                />
              </span>
            </NuxtLink>
          </div>
        </nav>
      </div>
      <div class="hidden items-center justify-center lg:flex lg:w-[300px]">
        <div
          v-if="fields.tag?.length"
          class="me-3 text-xs"
        >
          {{ fields.tag }}
        </div>

        <template v-if="props.companyUrl && useUser().value">
          <NuxtLink
            :to="props.companyUrl + '/print?p=' + (isFullProfile ? 'all' : activeItemSlug)"
            target="_blank"
            class="btn relative right-1 min-w-[290px] gap-2 !text-white"
            @click="downloadEvent"
          >
            Download {{ isFullProfile ? 'Full Profile' : 'Company Assessment' }}
            <NuxtIcon
              name="fairr:download"
              size="24"
            />
          </NuxtLink>
        </template>
      </div>
    </div>
  </UiContainer>
</template>
