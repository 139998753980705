export const formatDate = (date: Date, noYear = false, options: Intl.DateTimeFormatOptions = { month: "long" }) =>
  [
    date.getDate(),
    date.toLocaleDateString("en-GB", options),
    ...(noYear ? [] : [date.getFullYear()]),
  ].join(" ");

export const formatTime = (date: Date) =>
  `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes(),
  ).padStart(2, "0")}`;
