
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as _91slug_93A8syaJwCejMeta } from "/opt/build/repo/pages/about/our-team/[slug].vue?macro=true";
import { default as bookmarkso5ydueIkknMeta } from "/opt/build/repo/pages/dashboard/bookmarks.vue?macro=true";
import { default as companies6Kmpq0ZMauMeta } from "/opt/build/repo/pages/dashboard/companies.vue?macro=true";
import { default as engagementsKABkp30j8uMeta } from "/opt/build/repo/pages/dashboard/engagements.vue?macro=true";
import { default as indexViWjQzLqkmMeta } from "/opt/build/repo/pages/dashboard/index.vue?macro=true";
import { default as statements7rXQKpPIApMeta } from "/opt/build/repo/pages/dashboard/statements.vue?macro=true";
import { default as themesbe5TWrqQCAMeta } from "/opt/build/repo/pages/dashboard/themes.vue?macro=true";
import { default as toolsM95inCjSh0Meta } from "/opt/build/repo/pages/dashboard/tools.vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as reset_45password4iBG212Ge4Meta } from "/opt/build/repo/pages/reset-password.vue?macro=true";
import { default as animal_45pharmaji4y7ANq2JMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/animal-pharma.vue?macro=true";
import { default as antibiotics_45stewardshipXgkvxj8l1vMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/antibiotics-stewardship.vue?macro=true";
import { default as climate_45risk_45toolYIW3F9XSq9Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/climate-risk-tool.vue?macro=true";
import { default as downloadsgSd2JHIZwQMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/downloads.vue?macro=true";
import { default as index5WxZXBBalhMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/index.vue?macro=true";
import { default as meat_45sourcingzdIuxRZdh1Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/meat-sourcing.vue?macro=true";
import { default as printyWgqBajwzdMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/print.vue?macro=true";
import { default as protein_45diversificationjhrsna134AMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/protein-diversification.vue?macro=true";
import { default as protein_45producer_45indexhW5t5r7J0eMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/protein-producer-index.vue?macro=true";
import { default as restaurant_45antibioticsnQv07uyCA7Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/restaurant-antibiotics.vue?macro=true";
import { default as seafood_45traceabilityPlY3V5YPWlMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/seafood-traceability.vue?macro=true";
import { default as supply_45chain_45analysis9omX9BO8wKMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/supply-chain-analysis.vue?macro=true";
import { default as sustainable_45proteinso40G3T6OYjMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/sustainable-proteins.vue?macro=true";
import { default as waste_45pollutionFoatqvYp5sMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/waste-pollution.vue?macro=true";
import { default as working_45conditionsBDWXByoAktMeta } from "/opt/build/repo/pages/resources/companies-assessed/[name]/working-conditions.vue?macro=true";
import { default as _91name_93pM1worgYN9Meta } from "/opt/build/repo/pages/resources/companies-assessed/[name].vue?macro=true";
import { default as _91_46_46_46slug_93T02BWZXwmCMeta } from "/opt/build/repo/pages/resources/video-hub/player/[...slug].vue?macro=true";
import { default as searchi1lVFWwv2QMeta } from "/opt/build/repo/pages/search.vue?macro=true";
import { default as set_45passwordKLRgnMCIcZMeta } from "/opt/build/repo/pages/set-password.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "about-our-team-slug",
    path: "/about/our-team/:slug()",
    component: () => import("/opt/build/repo/pages/about/our-team/[slug].vue")
  },
  {
    name: dashboardB0S6bZ6pqpMeta?.name,
    path: "/dashboard",
    meta: dashboardB0S6bZ6pqpMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-bookmarks",
    path: "bookmarks",
    component: () => import("/opt/build/repo/pages/dashboard/bookmarks.vue")
  },
  {
    name: "dashboard-companies",
    path: "companies",
    component: () => import("/opt/build/repo/pages/dashboard/companies.vue")
  },
  {
    name: "dashboard-engagements",
    path: "engagements",
    component: () => import("/opt/build/repo/pages/dashboard/engagements.vue")
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/opt/build/repo/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-statements",
    path: "statements",
    component: () => import("/opt/build/repo/pages/dashboard/statements.vue")
  },
  {
    name: "dashboard-themes",
    path: "themes",
    component: () => import("/opt/build/repo/pages/dashboard/themes.vue")
  },
  {
    name: "dashboard-tools",
    path: "tools",
    component: () => import("/opt/build/repo/pages/dashboard/tools.vue")
  }
]
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password4iBG212Ge4Meta || {},
    component: () => import("/opt/build/repo/pages/reset-password.vue")
  },
  {
    name: _91name_93pM1worgYN9Meta?.name,
    path: "/resources/companies-assessed/:name()",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name].vue"),
    children: [
  {
    name: "resources-companies-assessed-name-animal-pharma",
    path: "animal-pharma",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/animal-pharma.vue")
  },
  {
    name: "resources-companies-assessed-name-antibiotics-stewardship",
    path: "antibiotics-stewardship",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/antibiotics-stewardship.vue")
  },
  {
    name: "resources-companies-assessed-name-climate-risk-tool",
    path: "climate-risk-tool",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/climate-risk-tool.vue")
  },
  {
    name: "resources-companies-assessed-name-downloads",
    path: "downloads",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/downloads.vue")
  },
  {
    name: "resources-companies-assessed-name",
    path: "",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/index.vue")
  },
  {
    name: "resources-companies-assessed-name-meat-sourcing",
    path: "meat-sourcing",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/meat-sourcing.vue")
  },
  {
    name: "resources-companies-assessed-name-print",
    path: "print",
    meta: printyWgqBajwzdMeta || {},
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/print.vue")
  },
  {
    name: "resources-companies-assessed-name-protein-diversification",
    path: "protein-diversification",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/protein-diversification.vue")
  },
  {
    name: "resources-companies-assessed-name-protein-producer-index",
    path: "protein-producer-index",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/protein-producer-index.vue")
  },
  {
    name: "resources-companies-assessed-name-restaurant-antibiotics",
    path: "restaurant-antibiotics",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/restaurant-antibiotics.vue")
  },
  {
    name: "resources-companies-assessed-name-seafood-traceability",
    path: "seafood-traceability",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/seafood-traceability.vue")
  },
  {
    name: "resources-companies-assessed-name-supply-chain-analysis",
    path: "supply-chain-analysis",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/supply-chain-analysis.vue")
  },
  {
    name: "resources-companies-assessed-name-sustainable-proteins",
    path: "sustainable-proteins",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/sustainable-proteins.vue")
  },
  {
    name: "resources-companies-assessed-name-waste-pollution",
    path: "waste-pollution",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/waste-pollution.vue")
  },
  {
    name: "resources-companies-assessed-name-working-conditions",
    path: "working-conditions",
    component: () => import("/opt/build/repo/pages/resources/companies-assessed/[name]/working-conditions.vue")
  }
]
  },
  {
    name: "resources-video-hub-player-slug",
    path: "/resources/video-hub/player/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/resources/video-hub/player/[...slug].vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/opt/build/repo/pages/search.vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: set_45passwordKLRgnMCIcZMeta || {},
    component: () => import("/opt/build/repo/pages/set-password.vue")
  }
]